import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Spinner from '../components/spinner';

/**
 * Never renders anything significant. Just a placeholder page for Auth0 redirect
 * callbacks.
 */
const CallbackPage = () => {
  const { isLoading } = useAuth0();

  return (
    <>
      <Spinner isLoading={isLoading} />
    </>
  );
};

export default CallbackPage;
